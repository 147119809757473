export const getRegistrationSteps = t => {
  return [
    {
      id: 1,
      label: t('registrations:RegistrationSteps.step1', 'Create Account'),
      pathname: '/registrations'
    },
    {
      id: 2,
      label: t('generic:PaymentDetails', 'Payment Details'),
      pathname: '/registrations/payment'
    }
  ]
}

export const getExistingUserCheckoutSteps = t => {
  return [
    {
      id: 1,
      label: t('generic:LogIn', 'Log In'),
      pathname: '/checkout'
    },
    {
      id: 2,
      label: t(
        'registrations:ExistingUserCheckoutSteps.step2',
        'Review Payment'
      ),
      pathname: '/checkout/payment'
    }
  ]
}

export const getExistingUserNoPaymentMethodCheckoutSteps = t => {
  return [
    {
      id: 1,
      label: t('generic:LogIn', 'Log In'),
      pathname: '/checkout'
    },
    {
      id: 2,
      label: t('generic:PaymentDetails', 'Payment Details'),
      pathname: '/checkout/payment'
    }
  ]
}

/*
  We need to do this because passing dynamic keys to our
  translation hook function will not work.
  i.e. t(`registrations:CreditCardForm.error.${error.code}`, error.message)
*/
export const getDynamicCardErrorMessages = t => {
  return {
    incomplete_number: t(
      'registrations:CreditCardForm.error.incomplete_number',
      'Your card number is incomplete'
    ),
    invalid_number: t(
      'registrations:CreditCardForm.error.invalid_number',
      'Your card number is invalid'
    ),
    incomplete_expiry: t(
      'registrations:CreditCardForm.error.incomplete_expiry',
      "Your card's expiration date is incomplete"
    ),
    invalid_expiry_year_past: t(
      'registrations:CreditCardForm.error.invalid_expiry_year_past',
      "Your card's expiration year is in the past"
    ),
    invalid_expiry_month_past: t(
      'registrations:CreditCardForm.error.invalid_expiry_month_past',
      "Your card's expiration date is in the past"
    ),
    incomplete_cvc: t(
      'registrations:CreditCardForm.error.incomplete_cvc',
      "Your card's security code is incomplete"
    )
  }
}

export const GROWSUMO_PARTNER_KEY = 'growSumoPartnerKey'
export const PS_PARTNER_KEY = 'ps_partner_key'
export const PS_60_DAYS = 'ps_60_days'
export const FREEMIUM_AFF_ID_KEY = 'freemium_aff_id_key'
export const PARTNER_STACK_XID = 'ps_xid'

export const DEFAULT_EXPIRED_CAMPAIGN_REDIRECT =
  'https://www.junglescout.com/offer/expired-promotion'

export const DEFAULT_EXPIRED_CAMPAIGN_REDIRECT_CHINA =
  'https://www.junglescout.cn/expiration-promotion'

export const CHINESE_CHECKOUT_STEPS = t => {
  return [
    {
      id: 1,
      stepNumber: 1,
      stepHeader: t(
        'registrations:ChineseCheckoutHeader.step1',
        'Personal Information'
      )
    },
    {
      id: 2,
      stepNumber: 2,
      stepHeader: t('registrations:ChineseCheckoutHeader.step2', 'Payment')
    }
  ]
}

export const LOCAL_STORAGE_KEYS_REGISTRATIONS = {
  APT_TOKEN: 'REGISTRATIONS_APT_TOKEN'
}

export const REGISTRATIONS_PROCESSING_STAGES = {
  processingRegistration: 'PROCESSING_REGISTRATION',
  processingSuccess: 'PROCESSING_SUCCESS'
}
